var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "search-term" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, model: _vm.queryParams }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "时间：" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "daterange",
                      "range-separator": "至",
                      "start-placeholder": "开始时间",
                      "end-placeholder": "结束时间",
                      "value-format": "yyyy-MM-dd"
                    },
                    model: {
                      value: _vm.queryParams.time_range,
                      callback: function($$v) {
                        _vm.$set(_vm.queryParams, "time_range", $$v)
                      },
                      expression: "queryParams.time_range"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "客户信息：" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入客户的推老板账号/ID/备注" },
                    model: {
                      value: _vm.queryParams.keyword,
                      callback: function($$v) {
                        _vm.$set(_vm.queryParams, "keyword", $$v)
                      },
                      expression: "queryParams.keyword"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择" },
                      model: {
                        value: _vm.queryParams.status,
                        callback: function($$v) {
                          _vm.$set(_vm.queryParams, "status", $$v)
                        },
                        expression: "queryParams.status"
                      }
                    },
                    _vm._l(_vm.statusOptions, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.requestData }
                    },
                    [_vm._v("查询")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-button", { on: { click: _vm.searchReset } }, [
                    _vm._v("重置")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: { data: _vm.data, border: "", stripe: "" }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "CreatedAt", label: "时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("formatDate")(scope.row.CreatedAt)) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "userId", label: "客户" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(scope.row.userId) +
                        " - " +
                        _vm._s(scope.row.user_phone) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "user_remark", label: "客户备注" }
          }),
          _c(
            "el-table-column",
            {
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      scope.row.type === 3
                        ? [_vm._v("购买")]
                        : scope.row.type === 2
                        ? [_vm._v("月账单")]
                        : scope.row.type === 1
                        ? [_vm._v("技术服务费")]
                        : [_vm._v("未知")]
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _vm._v(" 类型"),
                  _c("MyTooltip", {
                    attrs: {
                      content:
                        "购买：购买推老板产品；<br>月账单：含技术服务费、短信费、品牌券，每月1号统计"
                    }
                  })
                ],
                1
              )
            ],
            2
          ),
          _c("el-table-column", {
            attrs: { prop: "amount", label: "金额(元)" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm._f("fentoyuan")(scope.row.amount)) + " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "状态", prop: "status", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.status > 0
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v("已开票")
                        ])
                      : _c("el-tag", { attrs: { type: "warning" } }, [
                          _vm._v("待开票")
                        ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.status > 0
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "medium" },
                            on: {
                              click: function($event) {
                                return _vm.handleInvoice(
                                  scope.$index,
                                  scope.row
                                )
                              }
                            }
                          },
                          [_vm._v("未开票")]
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "medium" },
                            on: {
                              click: function($event) {
                                return _vm.handleInvoice(
                                  scope.$index,
                                  scope.row
                                )
                              }
                            }
                          },
                          [_vm._v("已开票")]
                        )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        staticStyle: { "text-align": "center" },
        attrs: {
          "current-page": _vm.queryParams.page,
          "page-size": _vm.queryParams.pageSize,
          "page-sizes": [10, 30, 50, 100],
          total: _vm.total,
          layout: "total, sizes, prev, pager, next, jumper"
        },
        on: {
          "update:currentPage": function($event) {
            return _vm.$set(_vm.queryParams, "page", $event)
          },
          "update:current-page": function($event) {
            return _vm.$set(_vm.queryParams, "page", $event)
          },
          "update:pageSize": function($event) {
            return _vm.$set(_vm.queryParams, "pageSize", $event)
          },
          "update:page-size": function($event) {
            return _vm.$set(_vm.queryParams, "pageSize", $event)
          },
          "current-change": _vm.requestData,
          "size-change": _vm.requestData
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }