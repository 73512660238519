import _objectSpread from "/Volumes/workspace/project/twiboss/frontend/twi-super-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.number.to-fixed.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { invoice, getBillList } from '@/api/bill';
import MyTooltip from "@/components/elTooltip";
import { formatTimeToStr } from "@/utils/date";
export default {
  name: "FinanceBills",
  components: {
    MyTooltip: MyTooltip
  },
  filters: {
    formatDate: function formatDate(time) {
      if (time) {
        return formatTimeToStr(new Date(time), "yyyy-MM-dd");
      }

      return "";
    },
    fentoyuan: function fentoyuan(amount) {
      return (amount / 100).toFixed(2);
    }
  },
  data: function data() {
    return {
      data: [],
      loading: false,
      queryParams: {
        start: "",
        end: "",
        time_range: [],
        keyword: "",
        Type: 0,
        status: 0,
        page: 1,
        pageSize: 10
      },
      total: 0,
      statusOptions: [{
        value: 0,
        label: '全部'
      }, {
        value: 1,
        label: '待开票'
      }, {
        value: 2,
        label: '已开票'
      }]
    };
  },
  created: function created() {
    this.requestData();
  },
  methods: {
    searchReset: function searchReset() {
      this.queryParams = {
        start: "",
        end: "",
        time_range: [],
        keyword: "",
        Type: 0,
        status: 0,
        page: 1,
        pageSize: 10
      };
      this.requestData();
    },
    requestData: function requestData() {
      var _this = this;

      this.loading = true;
      this.queryParams.start = this.queryParams.time_range[0] || "";
      this.queryParams.end = this.queryParams.time_range[1] || "";
      getBillList(_objectSpread({}, this.queryParams)).then(function (res) {
        _this.loading = false;
        _this.data = res.data.list;
        _this.total = res.data.total;
      });
    },
    handleInvoice: function handleInvoice(index, row) {
      var _this2 = this;

      this.$confirm('您确定要操作这项数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        invoice({
          bill_id: row.ID
        }).then(function (res) {
          if (res.code == 0) {
            _this2.$message.success('操作成功');

            var status = _this2.data[index].status;
            _this2.data[index].status = status > 0 ? 0 : 1;
          }
        });
      });
    }
  }
};