import service from '@/utils/request';
export var getBillList = function getBillList(params) {
  return service({
    url: "/bills/list",
    method: 'get',
    params: params
  });
};
export var invoice = function invoice(data) {
  return service({
    url: "/bills/invoice",
    method: 'post',
    data: data
  });
};